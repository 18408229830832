import { IDrawerMenu } from './types'

export const drawerMenu: IDrawerMenu[] = [
  {
    icon: 'el-icon-s-promotion',
    title: '在线缩短',
    to: '/dashboard'
  },
  {
    icon: 'el-icon-link',
    title: '我的链接',
    to: '/pages/link'
  },
  {
    icon: 'el-icon-money',
    title: '费用中心',
    to: '/pages/cost'
  },
  {
    icon: 'el-icon-edit',
    title: '实名认证',
    to: '/pages/real'
  },
  {
    icon: 'el-icon-tickets',
    title: '接口文档',
    to: '/pages/api'
  },
  {
    icon: 'el-icon-warning-outline',
    title: '系统消息',
    to: '/pages/news',
    tags: {
      use: true
    }
  }
]
