<template>
  <el-menu
    v-if="!responsively"
    router
    :default-active="activeIndex"
    :collapse="!isCollapse"
    background-color="rgb(21, 23, 32)"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <template v-for="(item, index) in drawerMenu" :key="`item-${ index }`">
      <el-menu-item :index="item.to">
        <em :class="item.icon"></em>
        <template #title>
          {{ item.title }}
          <el-tag size="mini" v-if="item.tags && item.tags.use" effect="dark" type="danger">
            {{ newsNums }}
          </el-tag>
        </template>
      </el-menu-item>
    </template>
    <em class="menu-icon el-icon-menu" @click="cutover"></em>
  </el-menu>

  <teleport v-else to='#app'>
    <el-drawer
      title="我是标题"
      v-model="isCollapse"
      direction="ltr"
      size="100%"
      :with-header="false">
      <template v-for="(item, index) in drawerMenu" :key="`item-${ index }`">
        <router-link @click="cutover" :to="item.to">
          <em :class="item.icon"></em>
          {{ item.title }}
          <el-tag size="mini" v-if="item.tags && item.tags.use" effect="dark" type="danger">
            {{ newsNums }}
          </el-tag>
        </router-link>
      </template>
      <em class="menu-icon el-icon-close" @click="cutover"></em>
    </el-drawer>
  </teleport>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { computed, ComputedRef, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { drawerMenu } from './data'

interface IState {
  newsNums: number
}

export default defineComponent({
  name: 'DashboardDrawer',
  setup () {
    const state = reactive<IState>({
      newsNums: 0
    })
    const stateAsRefs = toRefs(state)
    const store = useStore()
    const route = useRoute()
    const responsively: ComputedRef<boolean> = computed(() => store.getters.GET_RESPONSIVELY_MODE)
    const isCollapse: ComputedRef<boolean> = computed(() => {
      return store.getters.GET_MENU_BAR
    })

    const { proxy }: any = getCurrentInstance()
    const activeIndex: string = route.path
    const cutover = (): void => {
      store.commit('SET_MENU_BAR', !store.getters.GET_MENU_BAR)
    }

    const getNews = (): void => {
      proxy.$axios.get('/news/unread')
        .then((res: IResponse) => {
          state.newsNums = res.data.lists.length
        })
    }
    getNews()

    return {
      drawerMenu,
      isCollapse,
      cutover,
      activeIndex,
      responsively,
      ...stateAsRefs
    }
  }
})
</script>

<style lang="scss" scoped>
  .logo {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    border-right: solid 1px #e6e6e6;
  }
  .el-menu {
    overflow-y: auto;
    height: 100%;
    position: relative;
    border-right: 0;
  }
  .menu-icon {
    color: #FFF;
    font-size: 20px;
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    &:hover {
      background: #333;
    }
  }
</style>
