
import { IResponse } from '@/common/http'
import { computed, ComputedRef, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { drawerMenu } from './data'

interface IState {
  newsNums: number
}

export default defineComponent({
  name: 'DashboardDrawer',
  setup () {
    const state = reactive<IState>({
      newsNums: 0
    })
    const stateAsRefs = toRefs(state)
    const store = useStore()
    const route = useRoute()
    const responsively: ComputedRef<boolean> = computed(() => store.getters.GET_RESPONSIVELY_MODE)
    const isCollapse: ComputedRef<boolean> = computed(() => {
      return store.getters.GET_MENU_BAR
    })

    const { proxy }: any = getCurrentInstance()
    const activeIndex: string = route.path
    const cutover = (): void => {
      store.commit('SET_MENU_BAR', !store.getters.GET_MENU_BAR)
    }

    const getNews = (): void => {
      proxy.$axios.get('/news/unread')
        .then((res: IResponse) => {
          state.newsNums = res.data.lists.length
        })
    }
    getNews()

    return {
      drawerMenu,
      isCollapse,
      cutover,
      activeIndex,
      responsively,
      ...stateAsRefs
    }
  }
})
